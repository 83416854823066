<template>
    <page-header-wrapper>
		<template slot="extra">
			<a-button @click="reback" type="primary"><a-icon type="rollback" />返回</a-button>
		</template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <div>
                    <div class="title">标题</div>
                    <div class="main"><a-input v-model="form.title" placeholder="请输入标题" /></div>
                </div>
                <div>
                    <div class="title">分类</div>
                    <div class="main">
                        <a-radio-group name="radioGroup" v-model="form.type" @change="getType">
                            <a-radio :value="item.id" v-for="(item,index) in category" :key="index">
                                {{item.title}}
                            </a-radio>
                        </a-radio-group>
                    </div>
                </div>
				<div>
				    <div class="title">日期</div>
				    <div class="main">
				        <a-date-picker v-model="form.date" format="YYYY-MM-DD HH:mm:ss" :defaultValue="moment(new Date(), 'YYYY-MM-DD HH:mm:ss')" style="width:210px"/>
				    </div>
				</div>
                <div>
                    <div class="title">封面图</div>
                    <div class="main">
                        <a-upload
                            :action="imgUpload"
							:data="dataObj"
                            :headers="headers"
                            list-type="picture"
                            :default-file-list="fileList"
                            class="upload-list-inline"
                            @change="handleChange">
                          <a-button v-show="!this.form.headimg"> <a-icon type="upload" /> 点击上传 </a-button>
                        </a-upload>
                    </div>
                </div>
                <div>
                    <div class="title">文章正文</div>
                    <div class="main">
                        <vue-ueditor-wrap v-model="form.content" :config="myConfig"></vue-ueditor-wrap>
                    </div>
                </div>
            </div>
            <div id="submit">
                <a-button @click="cancel">取消</a-button>
                <a-button type="primary" id="btn" @click="submit">提交</a-button>
            </div>    
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
    import VueUeditorWrap from 'vue-ueditor-wrap'
    import storage from 'store';
    import { ACCESS_TOKEN } from '@/store/mutation-types';
    import { getUrlKey,UEDITOR_HOME_URL,serverUrl,imgUpload,prefix,decrypt } from '@/utils' 
    import {
        getList,
        deliv,
        getWqrInfo,
        editWqrInfo,
        getWqrType
    } from '@/api/article'
    var disabled = true
    export default {
        name: '',
        components: {
            VueUeditorWrap
        },
        data() {
            return {
				moment,
                form:{
                    id:'',
                    title:'',
                    type:'1',
                    content:'',
                    headimg:'',
					date:'',
                },
                myConfig: {
                    // 编辑器不自动被内容撑高
                    autoHeightEnabled: false,
                    // 初始容器高度
                    initialFrameHeight: 360,
                    // 初始容器宽度
                    initialFrameWidth: '100%',
                    serverUrl: serverUrl,
                    UEDITOR_HOME_URL: UEDITOR_HOME_URL
                },
                category:[],    //分类
                imgUpload:imgUpload,  //图片上传
                fileList:[],   //已经上传的文件列表
                prefix:prefix,
				dataObj:{
					is_thumb:1
				},
                headers:{
                    token:storage.get(ACCESS_TOKEN)
                },
            }
        },
        created(){
            this.form.id = getUrlKey('id')||'';
            this.getCateList();
            if(this.form.id){
                this.getInfo();
            }
        },
        methods: {
            getCateList(){   //获取分类
                let params = {}
                getWqrType(params).then(res =>{
                    if(res.code==200){
                        this.category = res.msg
                    }
                })
            },
			getType(e){ //选择分类
				this.form.type = e.target.value
			},
            getInfo(){
                let params = {
                    id:this.form.id
                }
                getWqrInfo(params).then(res =>{
                    if(res.code==200){
                        let data = res.data
                        this.form.title = data.title
                        this.form.type = data.type
                        this.form.content = data.content.replace(/undefined/g, "")
                        this.form.headimg = data.head_img
						this.form.date = data.create_time?moment(data.create_time*1000).format('YYYY-MM-DD HH:mm:ss'):moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
                        if(data.head_img){
                            let arr = {
                                uid:'-1',
                                status: 'done',
                                name:'封面图',
                                url:this.prefix+data.head_img,
                            }
                            this.$set(this.fileList,0,arr)
                        }
                    }
                })
            },
            handleChange(info) {
                console.log('info',info)
                const status = info.file.status;
                if (status !== 'uploading') {
                    
                }
                if(status == "removed"){
                    this.form.headimg = ''
                }
                if (status === 'done') {
                    if(info.file.response.code==0){
                        let enData = info.file.response.data;
                        //enData = decrypt(enData);
                        this.form.headimg = enData.path;
                    }else if(info.file.response.code==-98){
                        this.$message.error(`登录已过期,请重新登录`);
                        setTimeout(_=>{
                            localStorage.clear();
                            location.reload();
                        },1200)
                    }
                } else if (status === 'error') {
                    this.$message.error(`${info.file.name} 上传失败.`);
                }
            },
            submit(){
                let params = {
                    id:this.form.id,
                    content:this.form.content.replace(/undefined/g, ""),
                    title:this.form.title,
                    type:this.form.type,
                    headimg:this.form.headimg,
					create_time:moment(this.form.date).format('YYYY-MM-DD HH:mm:ss')
                }
				
                if(!this.form.title){
                    this.$message.info('请输入标题')
                    return false
                }
				else if(!this.form.headimg){
                    this.$message.info('请上传封面图')
                    return false
                }
				else if(!this.form.content){
                    this.$message.info('请输入内容')
                    return false
                }
                if(disabled){
                    disabled = false
                    editWqrInfo(params).then(res =>{
                        if(res.code==200){
                            this.$message.success(this.form.id?'编辑成功':'新增成功')
                            this.reset()
                            setTimeout(_=>{
                                this.$router.go(-1)
                                disabled = true
                            },500)
                        }else{
							this.$message.error(res.msg);
                            disabled = true
                        }
                    })
                }
                
            },
            cancel(){
                this.reset();
                this.$router.go(-1);
            },
            reset(){
                this.form.id = ''
                this.form.title = ''
                this.form.content = ''
                this.form.headimg = ''
            },
			reback(){
				this.$router.go(-1)
			}
        },
        filters: {
        
        }
    }
</script>


<style scoped>
#submit{display: flex;justify-content: center;margin-top: 20px;}
#btn{margin-left:20px;}
.title{
    height:22px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:22px;
    margin-bottom: 20px;
}
.main{
    margin-bottom: 25px;
}
</style>

